<!--
  ポップアップの背景とポップアップ本体のラッパー
-->
<template>
  <transition name="popup">
    <div
      v-if="showPopup"
      class="popup-bg"
      @mousedown="closeFlag = true"
      @mouseup="closeModalBg()"
    >
      <div
        class="popup-wrap"
        :class="{
          'popup-main-max-height': maxHeight,
          'popup-main-max-width': maxWidth
        }"
        @click.stop="$emit('popup-inner-click')"
        @mousedown.stop
        @mouseup.stop="closeFlag = false"
      >
        <div class="popup-inner">
          <div class="popup-title">
            <texts :text="title" size="title" />
          </div>
          <div
            class="popup-main"
            :class="{
              'popup-main-max-height': maxHeight,
              'popup-main-max-width': maxWidth
            }"
          >
            <slot />
          </div>
        </div>
        <button v-if="!noClose" class="popup-close" @click="closeModal()">
          <icons iconName="close" size="large" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import icons from '@/components/atoms/icon'
import texts from '@/components/atoms/text'

export default {
  components: {
    icons,
    texts
  },
  data() {
    return {
      closeFlag: false
    }
  },
  model: { prop: 'showPopup', event: 'change' }, // v-modelにしたとき、結びつける先のpropとイベント
  props: {
    /** タイトルを表示する場合のテキスト */
    title: String,
    /** 画面いっぱいに表示するかどうか */
    maxHeight: Boolean,
    /** 画面いっぱいに表示するかどうか */
    maxWidth: Boolean,
    /** ポップアップを表示するかどうか */
    showPopup: Boolean,
    /** ポップアップを閉じれなく刷る */
    noClose: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      if (this.noClose) return
      this.$emit('close-modal')
      this.$emit('change', false)
    },
    closeModalBg() {
      if (this.closeFlag) {
        if (this.noClose) return
        this.$emit('close-modal')
        this.$emit('change', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  &-bg {
    position: fixed;
    top: adjustVH($headerTabHeight);
    left: adjustVW(240);
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw - adjustVW(240);
    height: 100vh - adjustVH($headerTabHeight);
    background: rgba(0, 0, 0, 0.2);
    opacity: 1;
    z-index: 1000;
    will-change: opacity;
  }
  &-wrap {
    position: relative;
    display: flex;
    max-width: calc(100% - #{$space-medium * 2});
    max-height: calc(100% - #{$space-medium * 2});
    padding: $space-medium $space-small $space-medium $space-medium;
    margin: $space-medium;
    background: $background;
    border-radius: adjustVW(16);
    opacity: 1;
    transform: scaleY(1);
    transition: $transition-base;
    will-change: transform, opacity;
    &-max-height {
      height: 100%;
    }
    &-max-width {
      width: 100%;
    }
  }
  &-inner {
    width: 100%;
    max-width: 100%;
  }
  &-title {
    display: flex;
    align-items: center;
    max-width: calc(100% - #{adjustVW(32)});
    height: adjustVW(48);
    margin: 0 adjustVW(72) $space-small 0;
  }
  &-main {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - #{adjustVW(64)});
    padding: 0 $space-small 0 0;
    @include scrollbar;
    &-max-height {
      height: 100%;
    }
    &-max-width {
      width: 100%;
    }
  }
  &-close {
    position: absolute;
    top: $space-small;
    right: $space-small;
    padding: $space-small;
    background: $background;
    border-radius: 9in;
    transition: $transition-base;
    &:hover {
      background: $background-decoration;
      opacity: 1;
    }
  }
  // Animation
  &-enter {
    &.popup-bg {
      opacity: 0;
      .popup-wrap {
        opacity: 0.5;
        transform: translateY(-$space-medium);
      }
    }
    &-active {
      &.popup-bg {
        transition: opacity ease-in-out $transition-base;
        .popup-wrap {
          transition: opacity $transition-base,
            transform ease-in-out $transition-base;
        }
      }
    }
    &-to {
      &.popup-bg {
        opacity: 1;
        .popup-wrap {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
  &-leave {
    &.popup-bg {
      opacity: 1;
      .popup-wrap {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &-active {
      &.popup-bg {
        transition: opacity ease-in-out $transition-base;
        .popup-wrap {
          transition: opacity ease-in-out $transition-base,
            transform ease-in-out $transition-base;
        }
      }
    }
    &-to {
      &.popup-bg {
        opacity: 0;
        .popup-wrap {
          opacity: 0;
          transform: translateY(-$space-medium);
        }
      }
    }
  }
}
</style>
