// { "F": 0.7692307692307693, "test_accuracy": 0.7, "n_classes": 2, "accuracy": 0.7, "precision": 0.7258064516129032, "recall": 0.8181818181818182 }

export const classification = ($t) => ({
  test_accuracy: {
    name: $t('trainedAi.metrics.test_accuracy'),
    tab: 'test',
    percentage: true,
    showSummery: true,
    showList: true,
    info: $t('trainedAi.metricsInfo.test_accuracy'),
    image: 'accuracy'
  },
  test_precision: {
    name: $t('trainedAi.metrics.precision'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.precision'),
    image: 'precision'
  },
  test_recall: {
    name: $t('trainedAi.metrics.recall'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.recall'),
    image: 'recall'
  },
  test_F: {
    name: $t('trainedAi.metrics.F'),
    tab: 'test',
    percentage: false,
    info: $t('trainedAi.metricsInfo.F')
  },
  test_macro_precision: {
    name: $t('trainedAi.metrics.macro_precision'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.macro_precision'),
    image: 'precision'
  },
  test_macro_recall: {
    name: $t('trainedAi.metrics.macro_recall'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.macro_recall'),
    image: 'recall'
  },
  test_overall_accuracy: {
    name: $t('trainedAi.metrics.overall_accuracy'),
    tab: 'test',
    percentage: true,
    showSummery: true,
    showList: true,
    info: $t('trainedAi.metricsInfo.overall_accuracy'),
    image: 'accuracy'
  },
  test_average_accuracy: {
    name: $t('trainedAi.metrics.average_accuracy'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.average_accuracy'),
    image: 'averageAccuracy'
  },
  test_macro_F: {
    name: $t('trainedAi.metrics.macro_F'),
    tab: 'test',
    info: $t('trainedAi.metricsInfo.macro_F')
  }
})

export const regression = ($t) => ({
  test_accuracy: {
    name: $t('trainedAi.metrics.test_accuracy_r2'),
    tab: 'test',
    percentage: false,
    showSummery: true,
    showList: true,
    info: $t('trainedAi.metricsInfo.test_accuracy_r2')
  },
  test_loss: {
    name: $t('trainedAi.metrics.test_loss'),
    tab: 'test',
    percentage: false,
    info: $t('trainedAi.metricsInfo.test_loss')
  },
  test_rmse: {
    name: $t('trainedAi.metrics.rmse'),
    tab: 'test',
    percentage: false,
    info: null
  },
  train_accuracy: {
    name: $t('trainedAi.metrics.train_accuracy_r2'),
    tab: 'train',
    percentage: false,
    info: $t('trainedAi.metricsInfo.train_accuracy_r2')
  },
  train_loss: {
    name: $t('trainedAi.metrics.train_loss'),
    tab: 'train',
    percentage: false,
    info: $t('trainedAi.metricsInfo.train_loss')
  }
})

export const convertDataset = ($t) => ({})

export const naturalLanguage = ($t) => ({
  test_accuracy: {
    name: $t('trainedAi.metrics.test_accuracy'),
    tab: 'test',
    percentage: true,
    showSummery: true,
    showList: true
  },
  test_precision: {
    name: $t('trainedAi.metrics.precision'),
    tab: 'test',
    percentage: true
  },
  test_recall: {
    name: $t('trainedAi.metrics.recall'),
    tab: 'test',
    percentage: true
  },
  test_F: {
    name: $t('trainedAi.metrics.F'),
    tab: 'test',
    percentage: true
  },
  test_macro_precision: {
    name: $t('trainedAi.metrics.macro_precision'),
    tab: 'test',
    percentage: true
  },
  test_macro_recall: {
    name: $t('trainedAi.metrics.macro_recall'),
    tab: 'test',
    percentage: true
  },
  test_overall_accuracy: {
    name: $t('trainedAi.metrics.overall_accuracy'),
    tab: 'test',
    percentage: true,
    showSummery: true,
    showList: true
  },
  test_average_accuracy: {
    name: $t('trainedAi.metrics.average_accuracy'),
    tab: 'test',
    percentage: true
  },
  test_macro_F: {
    name: $t('trainedAi.metrics.macro_F'),
    tab: 'test',
    percentage: true
  }
})

export const timeseries = ($t) => ({
  test_rmse: {
    name: $t('trainedAi.metrics.test_rmse'),
    tab: 'test',
    percentage: false,
    info: $t('trainedAi.metricsInfo.test_rmse')
  },
  test_mae: {
    name: $t('trainedAi.metrics.test_mae'),
    tab: 'test',
    percentage: false,
    info: $t('trainedAi.metricsInfo.test_mae')
  }
})

export const images = ($t) => ({
  test_accuracy: {
    name: $t('trainedAi.metrics.test_accuracy'),
    tab: 'test',
    percentage: true,
    showSummery: true,
    showList: true,
    info: $t('trainedAi.metricsInfo.test_accuracy'),
    image: 'accuracy'
  },
  test_precision: {
    name: $t('trainedAi.metrics.precision'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.precision'),
    image: 'precision'
  },
  test_recall: {
    name: $t('trainedAi.metrics.recall'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.recall'),
    image: 'recall'
  },
  test_F: {
    name: $t('trainedAi.metrics.F'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.F')
  },
  test_loss: {
    name: $t('trainedAi.metrics.test_loss'),
    tab: 'test',
    percentage: false,
    info: $t('trainedAi.metricsInfo.test_loss')
  },
  train_accuracy: {
    name: $t('trainedAi.metrics.train_accuracy'),
    tab: 'train',
    percentage: true,
    info: $t('trainedAi.metricsInfo.train_accuracy'),
    image: 'accuracy'
  },
  train_loss: {
    name: $t('trainedAi.metrics.train_loss'),
    tab: 'train',
    percentage: false,
    info: $t('trainedAi.metricsInfo.train_loss')
  }
})

export const imagesMultiClass = ($t) => ({
  test_precision: {
    name: $t('trainedAi.metrics.precision'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.precision'),
    image: 'precision'
  },
  test_recall: {
    name: $t('trainedAi.metrics.recall'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.recall'),
    image: 'recall'
  },
  test_F: {
    name: $t('trainedAi.metrics.F'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.F')
  },
  test_macro_precision: {
    name: $t('trainedAi.metrics.macro_precision'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.macro_precision'),
    image: 'precision'
  },
  test_macro_recall: {
    name: $t('trainedAi.metrics.macro_recall'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.macro_recall'),
    image: 'recall'
  },
  test_overall_accuracy: {
    name: $t('trainedAi.metrics.overall_accuracy'),
    tab: 'test',
    percentage: true,
    showSummery: true,
    showList: true,
    info: $t('trainedAi.metricsInfo.overall_accuracy'),
    image: 'accuracy'
  },
  test_average_accuracy: {
    name: $t('trainedAi.metrics.average_accuracy'),
    tab: 'test',
    percentage: true,
    info: $t('trainedAi.metricsInfo.average_accuracy'),
    image: 'averageAccuracy'
  },
  test_macro_F: {
    name: $t('trainedAi.metrics.macro_F'),
    tab: 'test',
    info: $t('trainedAi.metricsInfo.macro_F')
  },
  test_loss: {
    name: $t('trainedAi.metrics.test_loss'),
    tab: 'test',
    percentage: false,
    info: $t('trainedAi.metricsInfo.test_loss')
  },
  train_accuracy: {
    name: $t('trainedAi.metrics.train_accuracy'),
    tab: 'train',
    percentage: true,
    info: $t('trainedAi.metricsInfo.train_accuracy'),
    image: 'accuracy'
  },
  train_loss: {
    name: $t('trainedAi.metrics.train_loss'),
    tab: 'train',
    percentage: false,
    info: $t('trainedAi.metricsInfo.train_loss')
  }
})

export const indexes = ($t) => ({
  classification: {
    kappa: {
      index: 0,
      name: $t('trainedAi.metrics.kappa'),
      percentage: false,
      info: null
    },
    logloss: {
      index: 1,
      name: $t('trainedAi.metrics.logloss'),
      percentage: false,
      info: null
    },
    auc: {
      index: 2,
      name: $t('trainedAi.metrics.auc'),
      percentage: false,
      info: null
    },
    gini: {
      index: 3,
      name: $t('trainedAi.metrics.gini'),
      percentage: false,
      info: null
    }
  },
  regression: {
    mse: {
      index: 0,
      name: $t('trainedAi.metrics.mse'),
      percentage: false,
      info: null
    },
    mae: {
      index: 1,
      name: $t('trainedAi.metrics.mae'),
      percentage: false,
      info: null
    },
    medae: {
      index: 2,
      name: $t('trainedAi.metrics.medae'),
      percentage: false,
      info: null
    },
    mape: {
      index: 3,
      name: $t('trainedAi.metrics.mape'),
      percentage: false,
      info: null
    },
    max_ae: {
      index: 4,
      name: $t('trainedAi.metrics.max_ae'),
      percentage: false,
      info: null
    },
    min_ae: {
      index: 5,
      name: $t('trainedAi.metrics.min_ae'),
      percentage: false,
      info: null
    },
    mdape: {
      index: 6,
      name: $t('trainedAi.metrics.mdape'),
      percentage: false,
      info: null
    },
    poisson_deviance: {
      index: 7,
      name: $t('trainedAi.metrics.poisson_deviance'),
      percentage: false,
      info: null
    },
    gamma_deviance: {
      index: 8,
      name: $t('trainedAi.metrics.gamma_deviance'),
      percentage: false,
      info: null
    },
    tweedie_deviance: {
      index: 9,
      name: $t('trainedAi.metrics.tweedie_deviance'),
      percentage: false,
      info: null
    }
  },
  deep: {
    kappa: {
      index: 0,
      name: $t('trainedAi.metrics.kappa'),
      percentage: false,
      info: null
    },
    logloss: {
      index: 1,
      name: $t('trainedAi.metrics.logloss'),
      percentage: false,
      info: null
    },
    auc: {
      index: 2,
      name: $t('trainedAi.metrics.auc'),
      percentage: false,
      info: null
    },
    gini: {
      index: 3,
      name: $t('trainedAi.metrics.gini'),
      percentage: false,
      info: null
    }
  },
  finetuning_deep: {
    kappa: {
      index: 0,
      name: $t('trainedAi.metrics.kappa'),
      percentage: false,
      info: null
    },
    logloss: {
      index: 1,
      name: $t('trainedAi.metrics.logloss'),
      percentage: false,
      info: null
    },
    auc: {
      index: 2,
      name: $t('trainedAi.metrics.auc'),
      percentage: false,
      info: null
    },
    gini: {
      index: 3,
      name: $t('trainedAi.metrics.gini'),
      percentage: false,
      info: null
    }
  }
})

export const noAccuracy = () => ({})
export const rag = () => ({})
